import './Documentation.css';
import 'katex/dist/katex.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import { useState } from 'react';

function Sidebar({ onSelect }) {
    const [openItems, setOpenItems] = useState({});  // Track which items are toggled open

    const handleToggle = (item) => {
        setOpenItems(prevState => ({
            ...prevState,
            [item]: !prevState[item],
        }));
    };

    return (
        <div className="sidebar">
            <ul>
                <li
                    className={`outer-select ${openItems['overview'] ? 'open' : ''}`}
                    onClick={() => {
                        onSelect('overview');
                        handleToggle('overview');
                    }}
                >
                    <span>Overview</span>
                </li>
                <li
                    className={`outer-select ${openItems['The Calculation'] ? 'open' : ''}`}
                    onClick={() => {
                        onSelect('The Calculation');
                        handleToggle('The Calculation');
                    }}
                >
                    <span>The Greatness Calculation</span>
                    <ul className={`sub-menu ${openItems['The Calculation'] ? 'open' : ''}`}>
                        <li onClick={() => onSelect('basic-usage')}>Greatness Index</li>
                        <li onClick={() => onSelect('advanced-usage')}>Event Formula</li>
                    </ul>
                </li>
                <li
                    className={`outer-select ${openItems['Usage'] ? 'open' : ''}`}
                    onClick={() => {
                        onSelect('Usage');
                        handleToggle('Usage');
                    }}
                >
                    <span>Region Multipliers</span>
                    <ul className={`sub-menu ${openItems['Usage'] ? 'open' : ''}`}>
                        <li onClick={() => onSelect('basic-usage')}>Basic Usage</li>
                        <li onClick={() => onSelect('advanced-usage')}>Advanced Usage</li>
                    </ul>
                </li>
                <li
                    className={`outer-select ${openItems['API'] ? 'open' : ''}`}
                    onClick={() => {
                        onSelect('API Reference');
                        handleToggle('API');
                    }}
                >
                    <span>Consistency</span>
                </li>
                <li
                    className={`outer-select ${openItems['API'] ? 'open' : ''}`}
                    onClick={() => {
                        onSelect('API Reference');
                        handleToggle('API');
                    }}
                >
                    <span>Longevity</span>
                </li>
                <li
                    className={`outer-select ${openItems['API'] ? 'open' : ''}`}
                    onClick={() => {
                        onSelect('API Reference');
                        handleToggle('API');
                    }}
                >
                    <span>Player Contribution</span>
                </li>
                <li
                    className={`outer-select ${openItems['API'] ? 'open' : ''}`}
                    onClick={() => {
                        onSelect('API Reference');
                        handleToggle('API');
                    }}
                >
                    <span>Sell Factor</span>
                </li>
                <li
                    className={`outer-select ${openItems['API'] ? 'open' : ''}`}
                    onClick={() => {
                        onSelect('API Reference');
                        handleToggle('API');
                    }}
                >
                    <span>Skill Over Time Progression</span>
                </li>
            </ul>
        </div>
    );
}

function DocumentationContent({ section }) {
    switch (section) {
        case 'overview':
            return <h2>Overview</h2>;
        case 'The Calculation':
            return <h2>The Calculation</h2>;
        case 'Earnings':
            return <h2>Earnings</h2>;
        case 'Consistency':
            return <h2>Consistency</h2>;
        case 'Longevity':
            return <h2>Longevity</h2>;
        case 'Region-Multipliers':
            return <h2>Region Multipliers</h2>;
        case 'Player-Contribution':
            return <h2>Player Contribution</h2>;
        case 'Sell-Factor':
            return <h2>Sell Factor</h2>;
        case 'Skill-Progression':
            return <h2>Skill Progression</h2>;
        default:
            return <h2>Welcome to the Documentation</h2>;
    }
}

function Documentation() {
    const [selectedSection, setSelectedSection] = useState('overview');

    return (
        <div className="documentation-page">
            <Container fluid>
                <Row>
                    <Col md={3} className="sidebar-col">
                        <Sidebar onSelect={setSelectedSection} />
                    </Col>
                    <Col md={9} className="doc-content">
                        <DocumentationContent section={selectedSection} />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Documentation;
