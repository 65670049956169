import './global.css';
import 'katex/dist/katex.min.css';
import { Container } from 'react-bootstrap'; // Import Container from react-bootstrap
import './ContactUs.css'

function ContactUs() {
    return (
        <Container fluid>
            <main className="contact-main">

                <div className="contact-body">
                    <div className="contact-header">
                        <h2>Contact Us</h2>
                    </div>
                    <p>
                        Welcome to <b>FNCompRankings</b>. We’re always eager to hear from you. Whether you want to share feedback, ask questions, or simply say hello, we’re here and ready to listen.
                        At <b>fncomprankings.com</b>, your thoughts and suggestions help us grow, improve, and deliver a better experience.
                    </p>
                    <p><b>You can contact us for any of the following:</b></p>
                    <ul className="contact-list">
                        <li><b>Website Feedback:</b> If you have any thoughts, opinions, or comments about our website, its design, or functionality, we would love to hear them. Your feedback helps us create a better user experience.</li>
                        <li><b>Content Queries:</b> Have a question or concern about our content? Whether you’re looking for clarification or need more details on a particular topic, feel free to ask. We’re here to provide the information you need.</li>
                        <li><b>Corrections or Updates:</b> If you come across any information in our posts that seems incorrect, outdated, or missing, let us know. We strive to provide accurate, up-to-date content, and your insights help us ensure we’re delivering the best.</li>
                        <li><b>Design Suggestions:</b> Do you have ideas on how we can improve our website’s appearance or usability? Whether it's changing the theme, colors, or layout, we’d appreciate your thoughts.</li>
                        <li><b>Improvement Suggestions:</b> We’re always looking for ways to improve <b>FNCompRankings</b>. If you have any suggestions to enhance our site, whether in terms of content, tools, or features, don’t hesitate to share.</li>
                        <li><b>Technical Issues:</b> If you encounter any errors, bugs, or issues while using our site, please report them to us. We aim to provide a seamless experience, and your reports help us address problems quickly.</li>
                    </ul>
                    <p>
                        We welcome all your comments, suggestions, and concerns, as they help us make <b>FNCompRankings</b> a better platform for everyone.
                    </p>
                    <br />
                    <p><b>Don't hesitate to contact us:</b></p>
                    <p>
                        You can reach out to us via email at: <b>fortnitecomprankings@gmail.com</b>. We’ll do our best to respond as soon as possible and address any concerns you may have.
                        Thank you for being a part of our community and helping us grow!
                    </p>
                </div>
            </main>
        </Container>
    );
}

export default ContactUs;

