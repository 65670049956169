import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios'; // Import axios for API requests
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import CookieConsent, { getCookieConsentValue, resetCookieConsentValue } from 'react-cookie-consent';
import './App.css';
import './AppMobile.css';
import './scrollbar.css';
import './global.css';
import GlobalHeader from './GlobalHeader';
import Footer from './Footer';
import RankingsPage from './RankingsPage';
import Documentation from './Documentation';
import PrivacyPolicy from './PrivacyPolicy';
import NotFound from './NotFound';
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';
import SuggestPlayerForm from './SuggestPlayerForm';
import CurrentRankings from './CurrentRankings';
import Blog from './Blog';
import BlogDetail from "./BlogDetail";

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


const App = () => {
  const [cookieConsent, setCookieConsent] = useState(false);
  const [isGdprRegion, setIsGdprRegion] = useState(false);
  const [geoChecked, setGeoChecked] = useState(false); // To check if location check is done
  const location = useLocation();


  // List of GDPR countries
  const gdprCountries = ['AT', 'BE', 'BG', 'CY', 'CZ', 'DE', 'DK', 'EE', 'ES', 'FI', 'FR', 'GR', 'HR', 'HU', 'IE', 'IT', 'LT', 'LU', 'LV', 'MT', 'NL', 'PL', 'PT', 'RO', 'SE', 'SI', 'SK', 'GB'];

  useEffect(() => {
    const fetchGeolocation = async () => {
      try {
        const response = await axios.get('/api/geo');
        const countryCode = response.data.country;
        if (gdprCountries.includes(countryCode)) {
          setIsGdprRegion(true); // Set GDPR region if user is in EU/EEA
        }
      } catch (error) {
        console.error("Error fetching geolocation: ", error);
      } finally {
        setGeoChecked(true); // Mark location check as complete
      }
    };

    // Fetch geolocation on app start
    fetchGeolocation();

    // Check for existing cookie consent
    const consent = getCookieConsentValue("consentCookie");
    if (consent === "true") {
      setCookieConsent(true);
    }
  }, []);

  const handleAcceptCookie = () => {
    setCookieConsent(true);
  };

  const handleRejectCookie = () => {
    setCookieConsent(false);
  };

  return (
    <div className='wrapper'>
      {/* Load analytics and speed insights based on cookie consent or if user is outside GDPR regions */}
      {/*  {((cookieConsent && isGdprRegion) || (!isGdprRegion && geoChecked)) && <Analytics />} */}
      {/*  {((cookieConsent && isGdprRegion) || (!isGdprRegion && geoChecked)) && <SpeedInsights />} */}
      <Analytics />
      <SpeedInsights />
      <GlobalHeader />
      <Routes>

        <Route path="/" element={<RankingsPage />} />
        <Route path="/suggest-a-player" element={<SuggestPlayerForm />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:slug" element={<BlogDetail />} />
        <Route path="/current-rankings" element={<CurrentRankings />} />
        <Route path="/docs" element={<Documentation />} />



        <Route path="*" element={<NotFound />} />
      </Routes>

      {/* Show cookie consent banner only if in GDPR region */}
      {isGdprRegion && (
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          enableDeclineButton
          declineButtonText="Reject"
          cookieName="consentCookie"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px", fontFamily: 'Lexend' }}
          declineButtonStyle={{ background: "lightgray", color: "#4e503b", fontSize: "13px", fontFamily: 'Lexend' }}
          expires={180}
          onAccept={handleAcceptCookie}
          onDecline={handleRejectCookie}
        >
          We use technical and analytical cookies to ensure that we give you the best experience on our website. By clicking “Accept,” you agree to our website's cookie use as described in our <a href='/privacy-policy' style={{ color: "#61dafb", textDecoration: "underline", fontWeight: "bold" }}>
            Privacy Policy
          </a>.
        </CookieConsent>
      )}

      {location.pathname !== '/docs' && <Footer />}
    </div>
  );
};

export default App;
