import React from 'react'
import './NotFound.css'
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate();


    function redirectToHomePage(){

        
            navigate('/');
        
    }

    return (
        <div className='nf-wrapper' >

            <div className='nf-content' >
                <h1>404</h1>
                Oops, This Page is Not Found!
            </div>
            <button onClick={redirectToHomePage}  href='https://fncomprankings.com/'>Return Home</button>


        </div>
    )
}

export default NotFound