import './footer.css';


function Footer() {
    return (
        <footer className="site-footer flex-20">
            <div className="footer-container">
                <div className="text-alignment">
                    <div className="footer-links">
                        <div className="mr-4 md:mr-6">
                            <a href="/privacy-policy" className="hover-underline">Privacy Policy</a>
                        </div>
                        <div>
                            <a href="/about-us" className="hover-underline">About Us</a>
                        </div>
                        <div>
                            <a href="/contact-us" className="hover-underline">Contact Us</a>
                        </div>
                    </div>
                    <div className="footer-copyright">
                        <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">
                            © 2024 FNCompRankings All Rights Reserved.
                        </span>
                    </div>

                    <div className='socials'>
                        <a href="https://twitter.com/fncomprankings" target="_blank" rel="noopener noreferrer">
                            <ion-icon style={{ fontSize: '24px' }} name="logo-twitter"></ion-icon>
                        </a>
                        <a href="https://www.tiktok.com/@fncomprankings" target="_blank" rel="noopener noreferrer">
                            <ion-icon style={{ fontSize: '24px' }} name="logo-tiktok"></ion-icon>
                        </a>
                        <a href="https://www.youtube.com/@fncomprankings" target="_blank" rel="noopener noreferrer">
                            <ion-icon style={{ fontSize: '24px' }} name="logo-youtube"></ion-icon>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
