import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import './Blog.css';
import ComingSoon from './ComingSoon';

const Blog = () => {

    return (
        <div>
            <ComingSoon soonValue={'Soon'} />
        </div>
    );
};

export default Blog;
