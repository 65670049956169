import React, { useEffect, useState } from 'react';
import ThemeSwitch from './ThemeSwitch';
import './GlobalHeader.css';
import './toggle.css';

function GlobalHeader() {
    const [selectedNav, setSelectedNav] = useState('All-Time List'); // Track selected nav item

    // Effect to set the initial selected nav item based on current URL
    useEffect(() => {
        const currentPath = window.location.pathname; // Get the current path

        // Set the selected nav item based on the path
        if (currentPath === '/blog') {
            setSelectedNav('Posts');
        } else if (currentPath === '/') {
            setSelectedNav('All-Time List');
        } else if (currentPath === '/current-rankings') {
            setSelectedNav('Current Rankings');
        }
        else{
            setSelectedNav('');
        }
    }, []); // Empty dependency array means this runs once on mount

    const handleNavClick = (navItem) => {
        setSelectedNav(navItem); // Update selected nav item
    };

    return (
        <header className="site-header">
            <div className='logo-png-holder'>
                <a href="/" className='logo-png-holder'>
                    <img className='logo' width={60} height={60} src='./officialfncomprankingslogo.png' alt="Logo" />
                </a>
            </div>

            <div className='nav'>
                <ul>
                    <li>
                        <a href='/blog' onClick={() => handleNavClick('Posts')}>
                            <span className={selectedNav === 'Posts' ? 'selected' : ''}>
                                Posts
                            </span>
                        </a>
                    </li>
                </ul>
                <ul>
                    <li>
                        <a href='/' onClick={() => handleNavClick('All-Time List')}>
                            <span className={selectedNav === 'All-Time List' ? 'selected' : ''}>
                                All-Time List
                            </span>
                        </a>
                    </li>
                </ul>
                <ul>
                    <li>
                        <a href='/current-rankings' onClick={() => handleNavClick('Current Rankings')}>
                            <span className={selectedNav === 'Current Rankings' ? 'selected' : ''}>
                                Current Rankings
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
            {/*
                <div>
                    <ThemeSwitch />
                </div>
            */}
        </header>
    );
}

export default GlobalHeader;
