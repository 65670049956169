import React, { useState } from "react";
import ComingSoon from "./ComingSoon";

const CurrentRankings = () => {



    return (
        <div>
            <ComingSoon soonValue={'2025'} />
        </div>
    );
};

export default CurrentRankings;
