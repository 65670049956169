import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState, useRef } from 'react';
import { Container } from 'react-bootstrap'; // Import Container from react-bootstrap
import { Helmet } from "react-helmet";
import './App.css';
import './AppMobile.css';
import './scrollbar.css';
import './global.css';
import AdUnit from './AdUnit'
import PlayerTable from './PlayerTable'


function RankingsPage() {
  const navigate = useNavigate();
  const [sheetData, setSheetData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('All');
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lowestRank, setLowestRank] = useState('');
  const [trendFilter, setTrendFilter] = useState("1-month");
  const [searchQuery, setSearchQuery] = useState('');
  const [showTrends, setShowTrends] = useState(false);
  const contentRef = useRef(null); // Reference for the content div
  const middleSectionRef = useRef(null); // Reference for the middle-section div
  const ad_ad_aboveRef = useRef(null); // Reference to the specific div
  const lastChanged = 'Saturday, November 30, 2024';
  const FormattedTimeAgoResult = timeAgo(lastChanged);
  const [uniqueCountries, setUniqueCountries] = useState([]); 




  function timeAgo(dateString) {
    const now = new Date();
    const pastDate = new Date(dateString);
    const seconds = Math.floor((now - pastDate) / 1000);

    const intervals = {
      year: 31536000,
      month: 2592000,
      day: 86400,
      hour: 3600,
      minute: 60,
      second: 1,
    };

    if (seconds < 60) return "Today"; // Less than a minute ago
    if (seconds < intervals.minute) return `${seconds} seconds ago`;
    if (seconds < intervals.hour) return `${Math.floor(seconds / intervals.minute)} minutes ago`;
    if (seconds < intervals.day) return `${Math.floor(seconds / intervals.hour)} hours ago`;
    if (seconds < intervals.month) return `${Math.floor(seconds / intervals.day)} days ago`;
    if (seconds < intervals.year) return `${Math.floor(seconds / intervals.month)} months ago`;

    return `${Math.floor(seconds / intervals.year)} years ago`;
  }

  const filterPlayers = (query, Nationality) => {
    let filtered = sheetData;
    if (query) {
      filtered = filtered.filter((player) => player.Player.toLowerCase().includes(query));
    }
    if (Nationality && Nationality !== 'All') {
      filtered = filtered.filter((player) => player.Nationality === Nationality);
    }
    setFilteredPlayers(filtered);
  };

  const handleCountryChange = (event) => {
    const country = event.target.value;
    setSelectedCountry(country);
    filterPlayers(searchQuery, country);
  };

  useEffect(() => {
    const setMaxHeight = () => {
      if (middleSectionRef.current && contentRef.current) {
        const parentHeight = middleSectionRef.current.clientHeight; // Get parent height
        contentRef.current.style.maxHeight = `${parentHeight}px`; // Set max height to parent height
      }
    };

    setMaxHeight(); // Set max height on mount

    window.addEventListener('resize', setMaxHeight); // Adjust max height on window resize

    return () => window.removeEventListener('resize', setMaxHeight);
  }, []);

  const handleSeeDocsClick = () => navigate('/docs');
  const handleSuggestPlayerClick = () => navigate('/suggest-a-player');
  const handleTrendChange = (event) => setTrendFilter(event.target.value);
  const updatePlayerCards = (selectedTrend) => {
    // Logic to update all player cards based on the selected trend
  };
  const handleSearchChange = (event) => {


    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    setFilteredPlayers(sheetData.filter((player) => player.Player.toLowerCase().includes(query)));
  };

  useEffect(() => {

    // Set the content div's min-height to 1000px initially
    if (contentRef.current) {
      contentRef.current.style.minHeight = '1000px';
    }

    const fetchData = async () => {
      try {
        // Primary fetch from the Google Cloud bucket
        const response = await fetch('https://storage.googleapis.com/fn-alltime/goat-data.json');
        if (!response.ok) throw new Error('Network response was not ok');
  
        const data = await response.json();
        const updatedData = data.map(player => ({
          ...player,
          GreatnessScore: Math.round(player.GreatnessScore / 1000000).toLocaleString(),
        }));
  
        setSheetData(updatedData);
        setFilteredPlayers(updatedData);
        setLoading(false);
  
        // Find the lowest RANK value
        const lowestRank = Math.min(...updatedData.map(player => player.Rank));
        setLowestRank(lowestRank - 1);
  
        // Get unique countries
        const countries = [...new Set(updatedData.map(player => player.Nationality))].sort();
        setUniqueCountries(countries);
        
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
  
        // Fallback fetch from local JSON file
        try {
          const fallbackResponse = await fetch('/goat-data.json');
          if (!fallbackResponse.ok) {
            const text = await fallbackResponse.text();
            throw new Error('Fallback JSON error: ' + text);
          }
          const jsonData = await fallbackResponse.json();
          const updatedData = jsonData.map(player => ({
            ...player,
            GreatnessScore: Math.round(player.GreatnessScore / 1000000).toLocaleString(),
          }));

          setSheetData(updatedData);
          setFilteredPlayers(updatedData);
          setLoading(false);



        // Find the lowest RANK value
        const lowestRank = Math.min(...updatedData.map(player => player.Rank));
        setLowestRank(lowestRank - 1);

         // Get unique countries
         const countries = [...new Set(updatedData.map(player => player.Nationality))].sort();
         setUniqueCountries(countries);
  

        } catch (fallbackError) {
          console.error('Error fetching fallback JSON:', fallbackError);
          setLoading(false);
        }
      }
    };

    fetchData();

    // Clean dependency array for useEffect
  }, [contentRef]);

  const contentClass = `content ${loading ? 'loading' : (searchQuery ? 'search-active' : '')}`;




  return (
    <Container fluid>
       <Helmet>
        <title>Fortnite Competitive All-Time Ranking</title>
        <meta name="description" content="The Top 300 Fortnite Competitive Players All-Time Ranking." />
        <link rel="icon" href="https://fncomprankings.com/favicon.ico?v=2"></link>
        {/* Open Graph tags for social media */}
        <meta property="og:title" content="Competitive All-Time Ranking" />
        <meta property="og:description" content="The Top 300 Fortnite Competitive Players All-Time Ranking." />
        <meta property="og:image" content="https://fncomprankings.com/favicon.ico?v=2" />
        <meta property="og:url" content="https://www.fncomprankings.com" />
      </Helmet>
      <main>
        <div>
          <div className='banner_bannerWrapper___cNAM'>

          <a href="https://docs.fncomprankings.com" target="_blank" rel="noopener noreferrer"> <ion-icon name="link-outline"></ion-icon> See List Documentation</a>
          </div>

          {/* Top Section */}
          <div className="top-section">
            <div className="page-title">
              <header className="top">
                <h1>Greatest Fortnite Players of All-Time</h1>
              </header>
              <div className="bottom">
                <h2>
                  Ranking competitive players using a math-based formula
                  <span className="last-updated">
                    Last Updated <span >{FormattedTimeAgoResult}</span>
                  </span>
                </h2>
              </div>
            </div>

            {/* Filter and Search Section */}
            <div className="filter-cycle">
              <div className="search">
                <span className="search-icon material-symbols-outlined">search</span>
                <input
                  className="search-input"
                  type="search"
                  placeholder="Search for a player..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>
              <div className="country-filter">
                <label htmlFor="country-select">Country: </label>
                <select id="country-select" value={selectedCountry} onChange={handleCountryChange}>
                  <option value="All">All</option>
                  {uniqueCountries.map(country => (
                    <option key={country} value={country}>{country}</option>
                  ))}
                </select>
              </div>
              {/*<button className="filter-buttons" onClick={handleSeeDocsClick}>See Docs</button>*/}
              <button className="filter-buttons" onClick={handleSuggestPlayerClick}>Suggest A Player</button>
            </div>
          </div>

          <div className='top'>
            <div className="pad-left">

            </div>

            <div className="twitter-teaser twitter-colors" style={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
              <div className='holder' style={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center', minWidth: '100%' }}>



                <div className="teaser-content" style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', minHeight: '100%' }}>
                  <span style={{ color: 'rgb(255, 255, 255)', margin: '5px' }}>Follow us on Twitter to see who player #{lowestRank} is tomorrow&nbsp;</span>

                  <div class="container" style={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ backgroundColor: '#272A30' }} >
                      <img
                        style={{ color: 'rgb(29, 161, 242)', display: 'flex', justifyContent: 'center', alignContent: 'center' }}
                        alt="Twitter Icon"
                        width="14px"
                        height="14px"
                        src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/x-social-media-white-icon.png"
                      />
                    </div>

                    <div class="container" style={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                      <a
                        href="https://twitter.com/fncomprankings"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: 'rgb(29, 161, 242)', display: 'flex', marginTop: '5px', marginBottom: '5px', paddingLeft: '2px', paddingRight: '10px' }}

                      >
                        @fncomprankings
                      </a>
                    </div>
                  </div>
                </div>
              </div>



            </div>


            <div className="pad-right">

            </div>
          </div>

          {/* Middle Section */}
          <div className="s" >

            {/* Left Ad */}
            <div class="ad-ad-left">

            </div>



            <div className={contentClass} ref={contentRef}>
              {loading ? (
                <div className="spinner">
                  <div className="spinner-circle"></div>
                </div>
              ) : (

                <>
                  {/* Ad Above Table for Mobile */}
                  <div ref={ad_ad_aboveRef} className="ad-ad-above">
                    {/*<p>Ad Above (Mobile)</p>*/}
                  </div>

                  <PlayerTable data={filteredPlayers} showTrends={showTrends} />

                  {/* Ad Below Table for Mobile */}
                  <div className="ad-ad-below">
                    {/* <p>Ad Below (Mobile)</p>*/}
                  </div>
                </>
              )}
            </div>

            {/* Left Ad */}
            <div className="ad-ad-right">

            </div>
          </div>


        </div>
      </main>
    </Container>
  );
}

export default RankingsPage;
