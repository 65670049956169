import './AboutUs.css'



const AboutUs = () => {
    return (
      <div className="about-us">
        <h2>About Us</h2>
        <p>
          At FNCompRankings we aim to give the Fortnite community reliable, data-driven rankings that go beyond opinion and hype. We know how competitive and wild Fortnite can be and want to create a platform where players are ranked based on facts, not opinion. By focusing on actual data, we hope to reduce the subjectivity that clouds discussions around player performance in the Fortnite competitive scene.
        </p>
        <p>
          We use a ton of in-depth statistics, tracking everything from kills, placements, and damage to overall player consistency across tournaments like FNCS, DreamHack, and other top-tier events. We want to give fans, players, and analysts a clear, unbiased view of who’s actually good in competitive Fortnite, with rankings updated regularly to reflect recent performance.
        </p>
        <p>
          FNCompRankings is transparent. We want the community to know how we calculate our rankings. Whether you’re an esports fan, a player looking to see how you stack up, or a professional team looking for insights, we have the tools to get into the stats and make informed decisions.
        </p>
        <p>
          We love helping the Fortnite scene grow by providing facts, not just highlights. Fortnite is an ever-changing game, and we’ll do our best to keep our rankings up-to-date and accurate so everyone can see who’s really on top.
        </p>
        <p>
          In short, FNCompRankings is here to make the Fortnite competitive conversation matter. By providing data and removing opinion, we want to help fans, players, and orgs know what makes a GREAT player.
        </p>
      </div>
    );
  };
  
  export default AboutUs;
  