import React, { useState } from "react";
import './ComingSoon.css'


const ComingSoon = ( {soonValue} ) => {

    return (
        <div className="cs-div"  >
            <div>
                <ion-icon class="gear-icon-large" name="cog" ></ion-icon>
            </div>
            <div>
                Coming {soonValue}
            </div>
        </div>
    );
};

export default ComingSoon;
