import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import './BlogDetail.css'

const BlogDetail = () => {
    const { slug } = useParams();
    const [blogContent, setBlogContent] = useState("");
    const [blogMeta, setBlogMeta] = useState({});

    useEffect(() => {
        const loadBlog = async () => {
            const response = await fetch(`/blog/${slug}.md`);
            const text = await response.text();
            const [meta, content] = text.split(/---[\r\n]+/).slice(1); // Extract meta and content

            // Convert meta into an object
            const metaObj = {};
            meta.split('\n').forEach(line => {
                const [key, value] = line.split(':').map(item => item.trim());
                if (key && value) {
                    metaObj[key] = value;
                }
            });

            setBlogMeta(metaObj);
            setBlogContent(content);
        };

        loadBlog();
    }, [slug]);

    return (
        <div className="bsagsio">
            <div className="blog-detail">
                <h1>{blogMeta.title}</h1>

                <ReactMarkdown>{blogContent}</ReactMarkdown>
            </div>
        </div>
    );
};

export default BlogDetail;
